<template>
  <div class="new-challenge">
    <main-app-bar>
      <template v-slot:title>
        <span v-if="$attrs.id"> {{ challenge.name }} </span>
        <span v-else> Add New Challenge </span>
      </template>

      <template v-slot:actions>
        <div>
          <arrow-button
            v-text="'Challenge Details'"
            replace
            variants
            :done="stage > 1"
            :to="{
              name: !isCurrent ? 'new.challenge.details' : 'challenge.details',
              params: { id: currentNewChallengeId },
            }"
            arrow-right
          />
          <arrow-button
            v-show="hasWorkoutDays"
            v-text="'Workout Days'"
            :done="stage > 2"
            :disabled="!next"
            :to="{
              name: !isCurrent
                ? 'new.challenge.workout-days'
                : 'challenge.workout-days',
              params: { id: $attrs.id },
            }"
            arrow-left
            arrow-right
            replace
          />

          <arrow-button
            v-text="'Challenge Extra Information'"
            replace
            :disabled="!next"
            :done="stage > 3"
            :to="{
              name: !isCurrent
                ? 'new.challenge.rich-description'
                : 'challenge.rich-description',
              params: { id: currentNewChallengeId },
            }"
            arrow-left
            arrow-right
          />

          <arrow-button
            v-text="'Challenge Check Ins'"
            :done="stage > 4"
            :disabled="!next"
            :to="{
              name: !isCurrent
                ? 'new.challenge.checkins'
                : 'challenge.checkins',
              params: { id: currentNewChallengeId },
            }"
            arrow-left
            arrow-right
            replace
          />

          <arrow-button
            v-text="'Challenge Validity Variants'"
            :done="stage > 5"
            :disabled="!next"
            :to="{
              name: !isCurrent
                ? 'new.challenge.variants'
                : 'challenge.variants',
              params: { id: currentNewChallengeId },
            }"
            arrow-left
            arrow-right
            replace
          />

          <arrow-button
            v-text="'Challenge Videos'"
            :done="stage > 6"
            :disabled="!next"
            :to="{
              name: !isCurrent ? 'new.challenge.videos' : 'challenge.videos',
              params: { id: currentNewChallengeId },
            }"
            arrow-left
            arrow-right
            replace
          />
          <arrow-button
            v-text="'Challenge Workouts'"
            :done="stage > 7"
            :disabled="!next"
            :to="{
              name: !isCurrent
                ? 'new.challenge.workouts'
                : 'challenge.workouts',
              params: { id: currentNewChallengeId },
            }"
            arrow-left
            :arrow-right="hasDeload"
            replace
          />

          <arrow-button
            v-if="hasDeload"
            v-text="'Challenge Deload Workouts'"
            :done="stage > 8"
            :disabled="!next"
            :to="{
              name: !isCurrent
                ? 'new.challenge.deload.workouts'
                : 'challenge.deload.workouts',
              params: { id: currentNewChallengeId },
            }"
            arrow-left
            replace
          />
        </div>
      </template>
    </main-app-bar>

    <div class="mt-5 px-lg-12 px-md-6 px-5 pt-4 pb-8">
      <transition name="fade" mode="out-in" v-if="!loading">
        <router-view />
      </transition>
      <content-loader :loading="loading" alias="Challenge" v-else />
    </div>
  </div>
</template>

<script>
import ArrowButton from '@/components/elements/ArrowButton'
import MainAppBar from '@/layouts/shared/MainAppBar'
import ContentLoader from '@/components/elements/loaders/ContentLoader'

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiTrashCanOutline } from '@mdi/js'

export default {
  name: 'AddNewChallenge',

  components: {
    MainAppBar,
    ArrowButton,
    ContentLoader,
  },

  data() {
    return {
      loading: false,
      challenge: {},
      icons: {
        delete: mdiTrashCanOutline,
      },
    }
  },

  computed: {
    ...mapState({
      selectedChallenge: (state) => state.challenges.selectedChallenge,
    }),

    stage() {
      return this.$route.meta ? this.$route.meta.stage : 1
    },

    next() {
      return !!this.$attrs.id
    },

    currentNewChallengeId() {
      return this.$route.params.id
    },

    isCurrent() {
      return this.selectedChallenge.completed_at
    },

    isCreated() {
      return !!this.selectedChallenge.id
    },

    hasDeload() {
      return this.selectedChallenge.has_deload
    },
    hasWorkoutDays() {
      return this.selectedChallenge.has_workout_days
    },
  },

  created() {
    let challengeId = this.$attrs.id

    if (challengeId) {
      this.fetchChallenge(challengeId)
    }
  },

  methods: {
    ...mapActions({
      getChallenge: 'challenges/getChallenge',
      deleteChallenge: 'challenges/deleteChallenge',
    }),

    ...mapMutations({
      clearSelectedChallenge: 'challenges/clearSelectedChallenge',
    }),

    async fetchChallenge(challengeId) {
      this.loading = true
      let { data } = await this.getChallenge(challengeId)
      this.loading = false
      this.challenge = data
    },

    async deleteCurrentChallenge() {
      // await this.deleteChallenge(this.selectedChallenge).then(res => {
      //   console.log('deleted')
      // })
    },
  },

  beforeRouteLeave: function(to, from, next) {
    this.clearSelectedChallenge()

    next()
  },

  watch: {
    selectedChallenge(challenge) {
      this.challenge = challenge
    },
  },
}
</script>
