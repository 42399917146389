<template>
  <div class="bbr-content-loader">
    <v-overlay
      class="primary--text text-center"
      color="grey lighten-4"
      :value="loading"
      :opacity="1"
      absolute
    >
      <v-progress-circular :size="50" color="primary" indeterminate />
      <div class="pa-10">Loading {{ alias }}...</div>
    </v-overlay>
  </div>
</template>

<script>
export default {
  props: ['loading', 'alias'],
  name: 'ContentLoader',
}
</script>
